import Swiper from 'swiper';
import { FreeMode, Autoplay, Pagination, Keyboard, A11y, Navigation } from 'swiper/modules';

function createSwiperOptions(baseOptions, additionalOptions) {
  // Utilisez Object.assign pour fusionner les objets
  return Object.assign({}, baseOptions, additionalOptions);
}

const baseSwiperOptions = {
  loop: true,
  speed: 1000,
  autoHeight: false,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  freeMode: {
    enabled: true,
    sticky: true,
  },
  keyboard: {
    enabled: true,
  },
  a11y: true,
  modules: [FreeMode, Autoplay, Pagination, Keyboard, A11y, Navigation],
};

if (document.querySelector('.home-swiper')) {
  new Swiper(
    '.home-swiper',
    createSwiperOptions(baseSwiperOptions, {
      slidesPerView: 1,
      spaceBetween: 200,
      centeredSlides: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  );
}

// Initialize Swiper for '.home-swiper', '.home-sidebar', and '.partenaire-swiper'
['.home-sidebar', '.partenaire-swiper'].forEach(selector => {
  if (document.querySelector(selector)) {
    new Swiper(
      selector,
      createSwiperOptions(baseSwiperOptions, {
        slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
      })
    );
  }
});

// Initialize Swiper for '.swiper-container' specifically if it exists
if (document.querySelector('.swiper-container')) {
  new Swiper(
    '.swiper-container',
    createSwiperOptions(baseSwiperOptions, {
      slidesPerView: 1,
      spaceBetween: 30,
      // Update this part according to the specific needs of this swiper instance
      breakpoints: {
        // Adjust slidesPerView and spaceBetween for different screen sizes
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
      preloadImages: false, // Disable preloading of all images
      lazy: {
        loadPrevNext: true, // Load images for the next and previous slide
        loadPrevNextAmount: 3,
        loadOnTransitionStart: true, // Load images at the start of the transition
      },
      keyboard: {
        enabled: true, // Enable keyboard navigation
        onlyInViewport: true,
      },
      a11y: {
        enabled: true, // Enable accessibility features
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
        firstSlideMessage: 'This is the first slide',
        lastSlideMessage: 'This is the last slide',
        paginationBulletMessage: 'Go to slide {{index}}',
      },
      watchSlidesVisibility: true,
      watchOverflow: true, // Disable Swiper functionality when there are not enough slides for sliding
      roundLengths: true, // Round values of widths and heights to prevent blurry texts on usual resolution screens
      effect: 'slide', // Could be 'fade', 'flip', 'coverflow', or 'cube' for different effects
      speed: 500, // Speed of transition
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })
  );
}